export const faDropboxSign = {
  prefix: 'fak',
  iconName: 'dropboxSign',
  icon: [
    500,
    500,
    [],
    null,
    'M484 12.7C474 19.1 418 54.7 359 92L251 160L128 81.6C59.9 38.5 3.68 3.43 2.85 3.43C2.03 3.43 1.48 47 1.48 99.9V197L48.1 226C73.6 241 94.2 255 93.9 257C93.3 258 72.5 272 47.3 288L1.48 317V414C1.48 491 2.31 511 5.05 510C6.69 509 59.1 477 121 437C183 398 238 364 243 360L253 354L329 403C371 429 427 465 453 481C479 497 500 511 502 511C502 511 503 467 503 414L502 317L456 289C431 273 410 259 409 258C408 257 429 243 455 227L503 197V98.6C503 44.8 503 .685 503 .685C502 .959 494 6.17 484 12.7z',
  ],
};

export const faAcronis = {
  prefix: 'fak',
  iconName: 'acronis',
  icon: [
    500,
    500,
    [],
    null,
    'M239.916 0L0 512h76.495l47.017-103.36h299.11L469.637 512h76.496L306.217 0h-66.3zm31.628 87.422h3.192l3.621 9.368 67.99 149.081H199.782L267.996 96.79l3.548-9.368zM150.435 351.184l22.421-47.855h200.416l22.425 47.855H150.435z',
  ],
};

export const faAzure = {
  prefix: 'fak',
  iconName: 'azure',
  icon: [
    500,
    500,
    [],
    null,
    'M634.98 491.92L404.69 89.566l-89.85 180.942L473.02 453.822 179.45 487.159l455.528 4.762zM180.72 190.749L27.608 449.075l130.337-13.101 229.022-417.83L180.72 190.747z',
  ],
};

export const faEset = {
  prefix: 'fak',
  iconName: 'eset',
  icon: [
    500,
    500,
    [],
    null,
    'M145.292 197.435h162.903v-4.739c0-80.553-29.353-85.292-83.654-85.292-66.041-1.579-77.782 20.534-79.25 90.03zM0 255.875C0 61.6 39.625 0 227.477 0c174.642 0 226.009 50.543 226.009 255.875v22.113H145.292v4.738c0 90.03 13.208 121.62 82.185 121.62 45.495 0 80.718-4.737 83.652-60.019h142.357c-1.469 71.075-23.482 112.143-61.64 135.835-39.625 23.693-95.392 30.01-164.37 30.01C44.029 510.171 0 443.833 0 255.874z',
  ],
};

export const faExclaimer = {
  prefix: 'fak',
  iconName: 'exclaimer',
  icon: [
    500,
    500,
    [],
    null,
    'M252 501C142 501 45.5 430 12.8 326L65.4 309C91 391 166 445 252 445C360 445 447 357 447 250C447 142 358 55.5 252 55.5C154 55.5 72.5 128 58.3 223H98.1C111 151 175 95.3 252 95.3C337 95.3 407 165 407 250V277H154C165 320 205 351 250 351C286 351 320 333 337 302L384 329C356 375 304 405 250 405C174 405 110 350 96.7 277H0V250C0 112 112 0 250 0C388 0 501 112 501 250C502 388 390 501 252 501zM252 149C206 149 166 181 155 223H348C337 181 297 149 252 149z',
  ],
};

export const faKeepit = {
  prefix: 'fak',
  iconName: 'keepit',
  icon: [
    500,
    500,
    [],
    null,
    'M741.4 511.9c-79.3 .1-154.3-43.7-187.2-87.8c-7.9-10.5-19.8-26.5-20.2-39.1c-.2-8.4 2.5-16.5 5-20.8c11.4-20.2 35.1-61.5 70.9-123.4c5-7.6 13.5-12.7 21.7-12.8l198.5 .2c3.3 0 5.9 1.3 7.3 3.8c1.4 2.5 1.3 5.6-.4 8.4c-4.1 6.8-29.8 48.2-34.1 54.2c-4.6 6.3-9.1 6.3-14.3 6.3c0 0-126.6-.1-130.8-.1c-7.8 0-10.2 .8-13.8 6.9c-6.7 11.4-26.8 46-26.8 46c-2.1 3.6-1.9 7.5 .4 11.2c3.5 5.1 11.8 13.3 11.8 13.3c25.5 26.9 67.8 42.6 109.2 42.6c90.1 0 153.9-68.9 153.9-162.9c0-88.1-69-159.9-153.9-159.9c-46.3 0-87.4 18.2-122.3 54c-19.9 20.5-35.1 46.8-47.3 67.9c0 0-68.8 124.3-81.7 145.4C446.7 432.2 371.8 512 246.4 512c-66 0-128.6-27.3-174.9-75.3C25.1 388.6 .3 325.1 .3 256.3C.3 117.4 113 0 246.4 0c85.4 0 150.3 47.4 189.7 87.1c3.5 3.6 21.1 22 20.9 36c-.1 10.8-5.1 19.2-7.2 22.8L373.2 276.8c-3.8 6-12 13.1-22.1 13.1l-197.8 0c-3.3 0-6-1.4-7.4-3.9c-1.4-2.5-1.3-5.7 .3-8.4c4.2-7 30.2-48.3 34.4-54.1c4.6-6.3 9.1-6.3 14.2-6.3l132.1 0c7.8 0 10.4-.8 13.8-6.9c0 0 20.7-36.6 25.8-45.6c1.8-3.1 2.4-7.8-1.3-11.4C315.9 105.2 269.6 98 246.4 98C158.8 98 92.7 166.7 92.7 257.8c0 44.2 14.4 84.8 40.6 114.3c28.1 31.6 67.2 48.7 113.1 48.7c42 0 77.5-2.9 120-49.6c22-24.2 48.9-70.7 49.1-71.2c0 0 65.3-115.1 86.9-153.1c9.8-17.2 29.1-45 50.2-65.8C590.3 44.2 653.2 0 738.6 0c136.2 0 247 116 247 258.7c0 142-107.3 253.2-244.3 253.3z',
  ],
};

export const faImpossibleCloud = {
  prefix: 'fak',
  iconName: 'impossibleCloud',
  icon: [
    500,
    500,
    [],
    null,
    'M196 19.7c-.8 .3-3.3 1.8-5.5 3.2s-7.2 4.4-11 6.5c-11.7 6.4-19.3 10.8-24 13.6c-2.2 1.3-4.3 2.6-6.5 3.9c-2.4 1.4-4.8 2.8-7.1 4.3c-2 1.2-15.3 8.9-29.5 17.1S85.7 84.1 84.8 85.2c-2.3 2.6-2.8 133.1-.5 136c.7 .9 5.8 3.9 11.2 6.8s11.8 6.2 14 7.5s6.7 3.7 10 5.4c7.7 3.9 20.4 12 18.8 12.1c-1.3 0-10.9 5.4-18.8 10.5c-4.6 3-8.8 5.5-15.5 9.2c-1.4 .8-4.3 2.5-6.5 3.8s-5.4 3.1-7.1 4s-4.1 2.5-5.2 3.5l-2.1 2 0 66.8c0 70.7 0 70.1 4.3 70.1c.3 0 2.8 1.3 5.4 2.8s9.3 5.2 14.8 8.1s12.3 6.6 15 8.1s7.5 4.1 10.5 5.7c3.2 1.7 6.3 3.5 9.5 5.3c2.2 1.3 6.5 3.6 9.5 5.3s6.6 3.8 8 4.7s6 3.6 10.3 5.8l15.5 8.3c4.3 2.3 10.1 5.5 13.1 7.1l5.3 2.9 4.2-2.2c2.3-1.2 6.1-3.5 8.5-5.1s4.5-2.9 4.7-2.9c.6 0 16.4-9.9 24.2-15.2c4-2.7 7.4-4.8 7.7-4.8s3.4-1.9 7-4.2c7.5-4.9 11.6-7.4 35.9-22.2L314 419.7l.3-34.4 .3-34.4 7.2 4.4c3.9 2.4 7.6 4.6 8 4.8c.9 .4 28.3 17 32.8 19.9c15.2 9.7 14.3 9.7 28.5 .6c13.2-8.5 34.6-21.8 48.5-30c7.8-4.6 21.7-13.3 30.3-18.9c3.7-2.4 9.3-6 12.5-8L488 320l0-68.3 0-68.3-3.2-1.9c-1.7-1-3.5-2.1-5.2-3.1c-6.4-3.7-10.5-6-14.5-8.2c-2.5-1.4-5-2.8-7.5-4.2c-4.1-2.4-13.5-7.5-22-12c-4.9-2.6-9.7-5.2-14.5-7.9c-4.1-2.3-8.3-4.4-9.2-4.8s-1.7-1-1.7-1.4s-.5-.9-1-.9s-3.5-1.6-6.5-3.5s-5.7-3.5-5.9-3.5s-2.8-1.5-5.7-3.3c-19.2-11.9-16.5-12-39.3 1.7c-19.4 11.6-36.4 21.6-37 21.6c-.3 0-.5-15.7-.5-34.9l0-34.9-3.3-1.9c-1.8-1.1-6.4-3.6-10.2-5.7c-4.9-2.6-9.7-5.3-14.5-8c-4-2.2-8-4.5-12-6.7c-9.7-5.4-12.7-7.1-18-10.3c-6.6-3.9-13-7.5-22.5-12.6c-3.2-1.7-6.3-3.4-9.5-5.2c-22.8-12.8-24.3-13.4-28-12z',
  ],
};

export const faTwingate = {
  prefix: 'fak',
  iconName: 'twingate',
  icon: [
    500,
    500,
    [],
    null,
    'M285.1 15.3C148.8 113.7 123.4 135.2 108.6 164.8l-6.1 12.2-.5 126c-.3 69.3 .1 126.7 .9 127.5c2.4 2.4 70.3-44.3 71.3-49.1c.5-2.3 1.3-33.4 1.9-69.2c1.7-108.3 2.8-110.4 86.4-171.3L303 111.3l.1-39.4c.1-40.1-1.3-66-3.7-66c-.7 0-7.2 4.2-14.3 9.4zm46.6 109.3C188.4 230.8 196.4 212.9 200.3 421.9l1.5 82.2L256 466.5C404.3 363.7 402.8 366.2 401.9 232.4c-.3-42.8-1.1-95.7-1.8-117.7l-1.3-39.9-67.1 49.8z',
  ],
};
